import { useEffect, useState } from 'react';
import { useCurrentAccount, useWaysteClient } from '@alliance-disposal/client';
import { type Hauler, type Material, MaterialLabels, Materials } from '@alliance-disposal/transport-types';
import { Button, Checkbox, Dialog, Loading } from '@wayste/sour-ui';
import { SquaresPlusIcon } from '@heroicons/react/24/outline';

const MaterialSettings = () => {
    const client = useWaysteClient();
    const { hauler } = useCurrentAccount();
    const [showSelectMaterials, setShowSelectMaterials] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [materialList, setMaterialList] = useState<Material[]>([]);

    useEffect(() => {
        setMaterialList(hauler.materials as Material[]);
    }, []);

    const handleSelectMaterialsClick = () => {
        setShowSelectMaterials(true);
    };

    const handleSelectMaterialsSave = async (materialsArray: string[]) => {
        setIsLoading(true);
        if (!hauler) return;
        const response = await client.hauler().wayste.update({ id: hauler.id, materials: materialsArray });
        setMaterialList(response.materials as Material[]);
        setShowSelectMaterials(false);
        setIsLoading(false);
    };

    if (!hauler) return <Loading />;

    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center justify-between px-4">
                <h6 className="text-xl font-semibold leading-7 tracking-tight text-gray-800">Materials</h6>
                <div className="flex gap-2">
                    <Button
                        className="btn-wayste-flat"
                        onClick={() => handleSelectMaterialsClick()}
                        endIcon={<SquaresPlusIcon className="size-4" />}
                    >
                        Select Materials
                    </Button>
                </div>
            </div>
            {hauler?.materials && hauler.materials.length > 0 ? (
                <div className="flex flex-col gap-y-1">
                    {materialList
                        .sort((a, b) => MaterialLabels[a]?.localeCompare(MaterialLabels[b]))
                        .map((material) => {
                            const materialLabel = MaterialLabels[material];
                            if (!materialLabel) return null;
                            return (
                                <div
                                    className="grid grid-cols-1 items-center rounded bg-gray-100 py-1.5 pl-4 text-sm font-semibold text-gray-800 shadow"
                                    key={material}
                                >
                                    <div className="flex items-center gap-x-2">{materialLabel}</div>
                                </div>
                            );
                        })}
                </div>
            ) : (
                <div className="flex flex-col gap-y-4 overflow-auto rounded-lg border border-gray-300 bg-[#fcfcfd] p-4 text-sm shadow">
                    Select the Materials You Service
                </div>
            )}
            <SelectMaterialsDialog
                open={showSelectMaterials}
                onClose={() => setShowSelectMaterials(false)}
                onSave={handleSelectMaterialsSave}
                account={hauler}
                isLoading={isLoading}
            />
        </div>
    );
};

export default MaterialSettings;

interface SelectMaterialsDialogProps {
    open: boolean;
    onClose: () => void;
    account: Hauler.HaulerTransport;
    onSave: (materialsArray: string[]) => void;
    isLoading: boolean;
}

const SelectMaterialsDialog = ({ open, onClose, account, onSave, isLoading }: SelectMaterialsDialogProps) => {
    const handleSave = (data: string[]) => {
        onSave(data);
    };

    return (
        <Dialog open={open} onClose={onClose} className="max-w-2xl" styledTitle="Select Materials" variant="skinny-pullout">
            <div className="h-dvh overflow-auto">
                <div className="border-b px-4 pb-1 pt-2 text-left text-xl font-semibold leading-normal tracking-wide text-zinc-700">
                    Select Materials
                </div>
                <p className="px-4 pt-2 text-sm text-gray-600">
                    Select the materials that you service. The selected materials will be the only materials available to create orders
                    with.
                </p>
                <MaterialForm onSubmit={handleSave} account={account} />
                <hr className="my-4" />
                <div className="flex justify-end">
                    <div className="flex w-full flex-col gap-4 px-4 pb-4">
                        <Button className="btn-primary w-full" form="account-material-select-form" loading={isLoading} type="submit">
                            Save
                        </Button>
                        <Button className="btn-dark-grey-outlined w-full" onClick={onClose} disabled={isLoading}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

interface MaterialFormProps {
    onSubmit: (materialsArray: string[]) => void;
    account: Hauler.HaulerTransport;
}

export const MaterialForm = ({ onSubmit, account }: MaterialFormProps) => {
    const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);

    useEffect(() => {
        if (!account.materials) {
            setSelectedMaterials([]);
            return;
        }
        setSelectedMaterials(Object.values(account.materials));
    }, [account.materials]);

    const handleItemClick = (value: string) => {
        let updatedArray = [...selectedMaterials];
        if (selectedMaterials.includes(value)) {
            updatedArray = updatedArray.filter((item) => item !== value);
        } else {
            updatedArray.push(value);
        }
        setSelectedMaterials(updatedArray);
    };
    return (
        <form
            onSubmit={(e) => {
                // DON'T REMOVE PREVENT DEFAULT WILL CAUSE ENTIRE APP TO RELOAD
                e.preventDefault();
                onSubmit(selectedMaterials);
            }}
            id="account-material-select-form"
        >
            <ul className="relative m-0 list-none p-0 py-2">
                {Materials.map((material) => (
                    <div className="cursor-pointer px-4 py-2 hover:bg-slate-50" key={material} onClick={() => handleItemClick(material)}>
                        <Checkbox
                            inputProps={{
                                checked: selectedMaterials.includes(material),
                                onChange: () => handleItemClick(material),
                            }}
                            label={MaterialLabels[material]}
                        />
                    </div>
                ))}
            </ul>
        </form>
    );
};
